import get from '@invitato/helpers/dist/getLocale';

export default {
  back: get('Kembali', 'Back'),
  button: get('LIHAT POTRET KAMI', 'MORE CAPTURE OF US'),
  ayatName: get('Kolose 3:14', 'Kolose 3:14'),
  ayatNameMuslim: get('Q.S. Ar-Rum: 21', 'Q.S. Ar-Rum: 21'),
  ayat: get(
    `Dan di atas semuanya itu: kenakanlah kasih, sebagai pengikat yang mempersatukan dan menyempurnakan.`,
    `So they are no longer two, but one. Therefore what God has joined together, let no one separate.`,
  ),
  ayatMuslim: get(
    `Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan diantaramu rasa kasih dan sayang. Sungguh, pada yang demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.`,
    `And of His signs is that He created for you from yourselves mates that you may find tranquility in them, and He placed between you affection and mercy. Indeed in that are signs for a people who give thought.`,
  ),
};