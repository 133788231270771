import React from 'react';
import { Box, Center, Text, Heading, AspectRatio } from '@chakra-ui/react';

import InstagramButton from '@/components/Common/InstagramButton';
import WithAnimation from '@/components/Common/WithAnimation';
import DividerComponent from '@components/Common/Divider';
import Image from '@/components/Common/LazyImage';

import { IMG_COUPLE } from '@/constants/assets';
import {
  IG_GIRL,
  IG_BOY,
  GIRL_PARENT_NAME,
  BOY_PARENT_NAME,
  IS_BOY_FIRST,
  BOY_NAME_SHORT,
  GIRL_NAME_SHORT,
} from '@/constants';

function SinglePhoto({ ...rest }) {
  const renderText = ({ name, instagramId, parentName, nameProps = {} }) => {
    return (
      <Box textAlign="center">
        <Heading
          fontSize="55px"
          lineHeight="76%"
          margin="8px 0"
          fontStyle="italic"
          letterSpacing="1px"
          fontWeight="bold"
          fontFamily="body"
          color="secondaryColorText"
          dangerouslySetInnerHTML={{ __html: name }}
          {...nameProps}
        />
        <DividerComponent width="50%" />
        <Text fontSize="xl" color="mainColorText" dangerouslySetInnerHTML={{ __html: parentName }} />
        {false && (
          <Center margin="24px 0">
            <WithAnimation>
              <InstagramButton
                id={instagramId}
                text={instagramId}
                color="white"
                onlyIcon={false}
                size="sm"
                fontFamily="body"
                fontWeight="normal"
                bgColor="bgAlternative"
                letterSpacing="2px"
                boxShadow="xl"
              />
            </WithAnimation>
          </Center>
        )}
      </Box>
    );
  };

  const renderBoy = () => {
    return renderText({
      name: BOY_NAME_SHORT,
      instagramId: IG_BOY,
      parentName: BOY_PARENT_NAME,
    });
  };

  const renderGirl = () => {
    return renderText({
      name: GIRL_NAME_SHORT,
      instagramId: IG_GIRL,
      parentName: GIRL_PARENT_NAME,
    });
  };

  return (
    <Box bgColor="bgPrimary" padding="62px 0 42px 0" {...rest}>
      <Box bgColor="bgSecondary" height="250px" paddingTop="24px">
        <Heading fontStyle="italic" textAlign="center" color="mainColorText" size="lg" fontWeight="normal" fontFamily="body">
          {IS_BOY_FIRST ? '50th Wedding Anniversary' : 'Bride & Groom'}
        </Heading>
      </Box>
      {false && (
        <WithAnimation left>
          <AspectRatio
            display="block"
            margin="0 auto"
            marginTop="-140px"
            transform="translateX(35px)"
            zIndex={2}
            ratio={3 / 4}
            width="70%"
            maxW="200px"
          >
            <Image src={IMG_COUPLE} />
          </AspectRatio>
        </WithAnimation>
      )}
      <WithAnimation>
        <AspectRatio
          display="block"
          margin="0 auto"
          marginTop="-160px"
          zIndex={2}
          ratio={3 / 4}
          width="100%"
          maxW="300px"
        >
          <Image src={IMG_COUPLE} />
        </AspectRatio>
      </WithAnimation>
      <Box padding="24px">
        {IS_BOY_FIRST ? renderBoy() : renderGirl()}
        <Box height="24px" />
        {IS_BOY_FIRST ? renderGirl() : renderBoy()}
      </Box>
    </Box>
  );
}

export default SinglePhoto;