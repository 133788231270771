import React from 'react';
import { Box, Heading, Text, Image, AspectRatio, Center } from '@chakra-ui/react';

import useLang from '@hooks/useLang';
import useGuestInfo from '@/hooks/useGuestInfo';
import useInvitation from '@hooks/useInvitation';
import WithAnimation from '@/components/Common/WithAnimation';

import { THE_BRIDE, WEDDING_RESEPSI_FULLDATE } from '@/constants';
import { ENABLE_MUSLIM_LANGUAGE } from '@/constants/feature-flags';
import { IMG_WELCOMING_SECTION, ASSETS_FLOWER_LEFT } from '@/constants/assets';

import locales from './locales';

function WelcomeV4() {
  const { name } = useGuestInfo();
  const isInvitation = useInvitation();
  const lang = useLang();

  return (
    <Box bgColor="bgPrimary" textAlign="center" color="mainColorText" padding="42px 0">
      <WithAnimation>
        <Text letterSpacing="4px" fontSize="sm">
          50TH WEDDING ANNIVERSARY
        </Text>
      </WithAnimation>
      <WithAnimation>
        <Heading
          margin="16px 0"
          fontWeight="normal"
          fontSize="3xl"
          letterSpacing="2px"
          lineHeight="80%"
          fontFamily="body"
          fontStyle="italic"
          dangerouslySetInnerHTML={{ __html: THE_BRIDE.replace('&', '& <br />') }}
        />
      </WithAnimation>
      <WithAnimation>
        <Text
          letterSpacing="2px"
          textTransform="uppercase"
          fontSize="sm"
          dangerouslySetInnerHTML={{ __html: WEDDING_RESEPSI_FULLDATE }}
        />
      </WithAnimation>
      <Box
        margin="32px 0"
        padding="16px 24px"
        bgColor="bgSecondary"
        border="1px solid"
        borderColor="mainColorText"
        borderLeftColor="bgSecondary"
        borderRightColor="bgSecondary"
      >
        {/* Dear Section */}
        <WithAnimation>
          <Box fontFamily="bodyAlternative" letterSpacing="2px">
            <Text>{locales.dear[lang]}</Text>
            <Text fontWeight="bold">{name ? `${name}` : locales.guest[lang]}</Text>
          </Box>
        </WithAnimation>
      </Box>
      {ENABLE_MUSLIM_LANGUAGE
        ?
          <>
            <Text paddingTop="16px">
              Assalamualaikum Warahmatullahi Wabarakatuh.
            </Text>
            <Text padding="0 24px 24px 24px" dangerouslySetInnerHTML={{ __html: isInvitation
                ? locales.welcomingInvitationMuslim[lang]
                : locales.welcomingAnnouncementMuslim[lang]
              }}
            />
          </>
        :
          <Text padding="16px 24px 24px 24px" dangerouslySetInnerHTML={{ __html: isInvitation
            ? locales.welcomingInvitationGeneral[lang]
            : locales.welcomingAnnouncementGeneral[lang]
          }}
      />
      }
      {/* Image Section */}
      <Box>
        <WithAnimation left>
          <Image src={ASSETS_FLOWER_LEFT} height="200px" position="absolute" left="15%" />
        </WithAnimation>
        <WithAnimation>
          <Center maxHeight="600px">
            <AspectRatio
              marginTop="42px"
              ratio={3 / 5}
              width="70%"
              maxW="220px"
              border="2px solid"
              borderColor="mainColorText"
            >
              <Image src={IMG_WELCOMING_SECTION} />
            </AspectRatio>
          </Center>
        </WithAnimation>
      </Box>
    </Box>
  );
}

export default React.memo(WelcomeV4);