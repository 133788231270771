import React, { useState } from 'react';

import { string } from 'prop-types';
import { Box, Text, Heading, Center, Button, useToast } from '@chakra-ui/react';

import useLang from '@hooks/useLang';
import WithAnimation from '@components/Common/WithAnimation';
import copyToClipboard from '@invitato/helpers/dist/copyTextToClipboard';

import { BUTTON_PROPS } from '@/constants/colors';
import { ENABLE_GIFT_CONFIRMATION, ENABLE_LIVE_STREAMING } from '@/constants/feature-flags';

import txtWording from './locales';
import ModalBank from './ModalBank';
import ModalAddress from './ModalAddress';
import ModalConfirmation from './ModalConfirmation';

const MODAL_STATE = {
  bank: 'bank',
  address: 'address',
  idle: '',
  confirm: 'confirmation',
};

const DEFAULT_BOTTOM = {
  fontWeight: 'normal',
  bgColor: 'bgAlternative',
  color: 'white',
  marginTop: '8px',
  size: 'sm',
  minWidth: '147px',
  fontFamily: 'body',
  textTransform: 'uppercase',
  ...BUTTON_PROPS,
};

/**
 * Function to render GiftCard component with Modal Info
 * @returns {JSX.Element}
 */
function GiftCardSection({ ...rest }) {
  const [modalState, setModalState] = useState(MODAL_STATE.idle);
  const toast = useToast();
  const lang = useLang();

  const onCopyText = async (text, message = '') => {
    const result = await copyToClipboard(text);

    if (result) {
      setModalState(MODAL_STATE.idle);
      toast({
        title: txtWording.success[lang],
        description: message || txtWording.successCopyRekening[lang],
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: 'Oops!',
        description: txtWording.failedCopied[lang],
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const onClose = () => {
    setModalState(MODAL_STATE.idle);
  };

  return (
    <Box bgColor="bgPrimary" {...rest}>
      <Box padding={ENABLE_LIVE_STREAMING ? "0 42px 42px 42px" :"42px 42px 42px 42px"}>
        <Box bgColor="bgSecondary" padding="8px" borderRadius="24px">
          <Box padding="52px 32px">
            <Center>
              <WithAnimation>
                <Heading
                  textAlign="center"
                  marginBottom="16px"
                  size="3xl"
                  color="mainColorText"
                  fontWeight="normal"
                  lineHeight="110%"
                >
                  {txtWording.title[lang]}
                </Heading>
              </WithAnimation>
            </Center>
            <WithAnimation>
              <Text fontSize="sm" color="mainColorText" align="center" margin="16px 0" padding="0 12px 0 12px">
                {txtWording.desc[lang]}
              </Text>
            </WithAnimation>
            <Center>
              <WithAnimation>
                <Button {...DEFAULT_BOTTOM} onClick={() => setModalState(MODAL_STATE.bank)} letterSpacing="2px">
                  Bank Transfer
                </Button>
              </WithAnimation>
            </Center>
            <Center>
              <WithAnimation>
                <Button {...DEFAULT_BOTTOM} onClick={() => setModalState(MODAL_STATE.address)} letterSpacing="2px">
                  {txtWording.sendGift[lang]}
                </Button>
              </WithAnimation>
            </Center>
            {ENABLE_GIFT_CONFIRMATION && (
              <Center>
                <WithAnimation>
                  <Button {...DEFAULT_BOTTOM} onClick={() => setModalState(MODAL_STATE.confirm)} letterSpacing="2px">
                    {txtWording.confirm[lang]}
                  </Button>
                </WithAnimation>
              </Center>
            )}
          </Box>
        </Box>
      </Box>
      <ModalBank
        lang={lang}
        onClose={onClose}
        visible={modalState === MODAL_STATE.bank}
        onCopyText={onCopyText}
      />
      <ModalAddress
        lang={lang}
        onClose={onClose}
        visible={modalState === MODAL_STATE.address}
        onCopyText={onCopyText}
      />
      <ModalConfirmation
        lang={lang}
        isOpen={modalState === MODAL_STATE.confirm}
        onClose={onClose}
      />
    </Box>
  );
}

GiftCardSection.propTypes = {
  lang: string,
};

GiftCardSection.defaultProps = {
  lang: 'en',
};

export default React.memo(GiftCardSection);